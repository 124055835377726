import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import { updateForm } from "../../store/slices/InventorySlice";
import { useDispatch, useSelector } from "react-redux";

const NewAutoCompleteComponent = ({
  options = [],
  label = "PART #",
  valueKey = "",
  defaultValue = [],
}) => {
  const dispatch = useDispatch();

  const updateRedux = (newInputValue) => {
    const payload = {
      valueKey,
      value: newInputValue,
    };
    dispatch(updateForm(payload));
  };

  const [value, setValue] = React.useState(defaultValue);
  const [inputValue, setInputValue] = React.useState("");
  const reset = useSelector(({ inventory }) => inventory.toggleReset);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [reset]);

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
    updateRedux(newValue);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      className="fixed-height-autocomplete"
      multiple
      options={options}
      value={value}
      onChange={handleValueChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default NewAutoCompleteComponent;
